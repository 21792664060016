import React from 'react';
import { useTranslation } from 'next-i18next';
import styled from '@emotion/styled';
import { alpha } from '@mui/material/styles';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { useSearchFilterContext } from '../context/search-filter/search-filter-context';
import { SearchHelpModal } from './atoms/SearchHelpModal';

const StyledSearchWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    height: '35px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.2),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    [theme.breakpoints.up('md')]: {
        minWidth: '228px'
    }
}));

const StyledSearchHelp = styled(SearchHelpModal)(({ theme }) => ({
    position: 'absolute',
    right: '-2px',
    top: '-3px',
    fontSize: '0.75em',
    opacity: 0.2,
    '&:hover': {
        opacity: 1
    }
}));

export const SiteSearch = ({ className = '', inputId }: { readonly className?: string; readonly inputId: string }) => {
    const { t } = useTranslation();
    const { clearSearch, confirmedSearchValue, performSearch, siteSearchUIInputValue, setSiteSearchUIInputValue } =
        useSearchFilterContext();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== siteSearchUIInputValue) {
            setSiteSearchUIInputValue(e.target.value);
        }
    };

    const handleInputKeyUp = (e: React.KeyboardEvent) => {
        e.stopPropagation();

        if (e.key === 'Enter') {
            performSearch();
        }

        if (e.key === 'Escape') {
            clearSearch();
        }
    };

    const hasExecutedListingSearch = confirmedSearchValue === siteSearchUIInputValue;
    const showCancelIcon = siteSearchUIInputValue && hasExecutedListingSearch;

    const barEndIcon = showCancelIcon ? <CancelOutlinedIcon /> : <SearchIcon />;
    const barIconAction = hasExecutedListingSearch ? clearSearch : performSearch;

    return (
        <StyledSearchWrapper className={className}>
            <InputBase
                data-testid="binge-search"
                id={inputId}
                inputProps={{ 'aria-label': t('common:navigation.search') }}
                fullWidth
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp}
                placeholder={t('common:navigation.search_placeholder')}
                sx={{
                    color: 'inherit',
                    pt: 0.25,
                    px: 1.25,
                    pb: 1,
                    transition: (theme) => theme.transitions.create('width'),
                    width: '88%',
                    '> input:focus + .search-help': {
                        display: 'block'
                    }
                }}
                value={siteSearchUIInputValue}
            />
            <IconButton
                aria-label={t('navigation.clear_search')}
                disabled={hasExecutedListingSearch && !showCancelIcon}
                onClick={barIconAction}
                size="small"
                sx={{
                    pl: 1,
                    zIndex: 1,
                    color: '#ffffff',
                    position: 'absolute',
                    right: '2px',
                    top: '1px'
                }}
                title={t('navigation.clear_search')}
            >
                {barEndIcon}
            </IconButton>
            <StyledSearchHelp className="search-help" />
        </StyledSearchWrapper>
    );
};
