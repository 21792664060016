import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Paper from '@mui/material/Paper';
import { AppLocales } from '@bladebinge/types/src/api/models/user-preference';
import { DEFAULT_LANGUAGE_CODE } from '@bladebinge/web-service-common/src/constants/supported-language-keys';
import type { SupportedLanguage } from '@bladebinge/types';
import { useUserPreferences } from '../../context/user-preferences/user-preferences-context';
import { nextLocalStorage } from '../../utils/next-local-storage-helper';
import { useHasMounted } from '../../hooks/use-has-mounted';
import { SupportedLanguageSelector } from './SupportedLanguageSelector';

export const SiteTranslationLanguageSelector = ({ isUserSettings = false }: { readonly isUserSettings?: boolean }) => {
    const { i18n } = useTranslation();
    const hasMounted = useHasMounted();
    const { updatingPersistedUserPreferences, updatePersistedUserPreferencesValues, persistedUserPreferences } =
        useUserPreferences();
    const router = useRouter();
    const { asPath: routerAsPath, locale: routerLocale, query: routerQuery, pathname: routerPathname } = router;
    const { locale: localePreference } = persistedUserPreferences ?? {};

    const localStorageLocale = nextLocalStorage.get('userLocale');
    const persistedOrLocalPreference = localePreference
        ? localePreference
        : localStorageLocale
          ? localStorageLocale
          : DEFAULT_LANGUAGE_CODE;

    const [locale, setLocale] = useState<string>(persistedOrLocalPreference as SupportedLanguage);

    const persistLocaleUpdate = (updatedLocale: AppLocales) => {
        if (updatingPersistedUserPreferences) {
            return;
        }

        updatePersistedUserPreferencesValues({
            preferences: {
                locale: updatedLocale
            },
            subform: 'language'
        });
    };

    const handleLanguageSelect = (updatedLocale: SupportedLanguage) => {
        updatedLocale === DEFAULT_LANGUAGE_CODE
            ? nextLocalStorage.remove('userLocale')
            : nextLocalStorage.set('userLocale', updatedLocale);

        persistLocaleUpdate(updatedLocale as AppLocales);
        setLocale(updatedLocale);
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setLocale(persistedOrLocalPreference);
    }, [persistedOrLocalPreference]);

    useEffect(() => {
        if (localePreference && localePreference !== locale) {
            setLocale(localePreference);
        }
    }, [localePreference]);

    const updateTranslations = useMemo(
        () => async () => {
            const loggedInOrLocalStorageLocale = localePreference ?? locale;
            if (loggedInOrLocalStorageLocale && i18n?.language !== loggedInOrLocalStorageLocale) {
                const commonTranslationsResponse = await fetch(
                    `${process.env.ENV_UI_SERVER_URL}/locales/${loggedInOrLocalStorageLocale}/common.json`
                );
                const commonTranslations = await commonTranslationsResponse.json();
                const legalTranslationsResponse = await fetch(
                    `${process.env.ENV_UI_SERVER_URL}/locales/${loggedInOrLocalStorageLocale}/legal.json`
                );
                const legalTranslations = await legalTranslationsResponse.json();
                i18n?.addResourceBundle(loggedInOrLocalStorageLocale, 'common', commonTranslations, true, false);
                i18n?.addResourceBundle(loggedInOrLocalStorageLocale, 'legal', legalTranslations, true, true);
                i18n?.reloadResources();
                i18n?.changeLanguage(loggedInOrLocalStorageLocale);

                if (routerLocale !== loggedInOrLocalStorageLocale) {
                    router.push(
                        {
                            pathname: routerPathname,
                            query: routerQuery
                        },
                        routerAsPath,
                        {
                            locale: loggedInOrLocalStorageLocale,
                            shallow: true
                        }
                    );
                }
            }
        },
        [locale, localePreference]
    );

    useEffect(() => {
        updateTranslations();
    }, [localePreference, locale]);
    /* eslint-enable react-hooks/exhaustive-deps */

    // prevent next SSR render differing from Client render and erroring
    if (!hasMounted) {
        return null;
    }

    return (
        <Paper sx={{ p: 0.5 }}>
            <SupportedLanguageSelector
                preselectedLanguage={locale as SupportedLanguage}
                onSelectLanguage={handleLanguageSelect}
                isUserSettings
            />
        </Paper>
    );
};
