import React from 'react';
import { default as NextImage } from 'next/image';
import Link from 'next/link';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';
import { useMe } from '../context/me/me-context';
import { useReferralCodeContext } from '../context/referral-code/referral-code-context';
import { SiteTranslationLanguageSelector } from './FormComponents/SiteTranslationLanguageSelector';
import { ThemeSwitcher } from './FormComponents/ThemeSwitcher';
import { BladebingeShopIconLink } from './atoms/BladebingeShopIconLink';

const StyledCopyrightWrap = styled('div')(({ theme }) => ({
    width: '100%',
    textAlign: 'center',
    fontStyle: 'italic',
    color: theme.palette.getContrastText(theme.palette.common.black)
}));

const StyledFooterWrap = styled('footer')(({ theme }) => ({
    padding: theme.spacing(2, 0),
    fontSize: '.75rem',
    borderTop: `2px inset ${theme.palette.background.default}`
}));

const StyledFooterLink = styled(Link)(({ theme }) => ({
    display: 'block',
    textDecoration: 'none',
    color: '#ffffff',
    fontSize: '1.2em',
    overflowWrap: 'anywhere',
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    '&:hover': {
        textDecoration: 'underline'
    }
}));

const StyledFooterLinkList = styled('ul')(({ theme }) => ({
    listStyle: 'none',
    textAlign: 'right',
    padding: 0,
    '& > li': {
        marginTop: theme.spacing(0.25)
    }
}));

export const SiteFooter = () => {
    const { t } = useTranslation();
    const { id } = useMe();
    const { referredBy } = useReferralCodeContext();
    const isSignedIn = Boolean(id);

    const isNonProdEnv = process?.env?.ENV_CDK_LIFECYCLE !== 'prod';
    const showBuildVersion = process?.env?.ENV_BUILD_VERSION && isNonProdEnv;
    const referralCode = isNonProdEnv ? (referredBy ? referredBy : 'no referrer') : null;

    return (
        <StyledFooterWrap>
            <Grid container justifyContent="center">
                <Grid item xs={11} sm={11} lg={11} xl={11}>
                    <Grid container justifyContent="space-between" spacing={1}>
                        <Grid item xs={5} sm={5} lg={5} xl={5}>
                            <Grid container direction="column">
                                <Grid item xs={12} sx={{ my: 1 }}>
                                    <SiteTranslationLanguageSelector />
                                </Grid>
                                <Grid item xs={12}>
                                    <ThemeSwitcher />
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <Grid
                                        id="bladebinge_social_links"
                                        container
                                        spacing={1}
                                        justifyContent="flex-start"
                                    >
                                        <Grid item>
                                            <a
                                                href="https://www.instagram.com/bladebinge.com/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <InstagramIcon
                                                    color="secondary"
                                                    titleAccess={t('common:social_links.instagram')}
                                                />
                                            </a>
                                        </Grid>
                                        <Grid item>
                                            <a
                                                href="https://www.facebook.com/bladebinge/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <FacebookIcon
                                                    color="secondary"
                                                    titleAccess={t('common:social_links.facebook')}
                                                />
                                            </a>
                                        </Grid>
                                        <Grid item>
                                            <BladebingeShopIconLink />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item xs={5} sm={5} lg={5} xl={5}>
                            <Grid item xs={12} sx={{ mb: 1 }}>
                                <Typography
                                    component="div"
                                    variant="h6"
                                    sx={{
                                        textDecoration: 'underline',
                                        mb: 0,
                                        p: 0,
                                        fontSize: '1.4em',
                                        textAlign: 'right'
                                    }}
                                >
                                    {t('common:navigation.site_help')}
                                </Typography>
                                <StyledFooterLinkList>
                                    <li>
                                        <StyledFooterLink
                                            target="_blank"
                                            href="https://intercom.help/bladebinge/en/articles/9010873-buyer-s-guide"
                                        >
                                            {t('common:navigation.buyers_guide')}
                                        </StyledFooterLink>
                                    </li>
                                    <li>
                                        <StyledFooterLink target="_blank" href="https://intercom.help/bladebinge/en">
                                            {t('common:navigation.help_center')}
                                        </StyledFooterLink>
                                    </li>
                                    <li>
                                        <StyledFooterLink
                                            target="_blank"
                                            href="https://intercom.help/bladebinge/en/collections/3435134-policies-and-guidelines"
                                        >
                                            {t('common:navigation.policies_and_guideines')}
                                        </StyledFooterLink>
                                    </li>
                                    <li>
                                        <StyledFooterLink
                                            target="_blank"
                                            href="https://intercom.help/bladebinge/en/articles/9011002-seller-s-guide"
                                        >
                                            {t('common:navigation.sellers_guide')}
                                        </StyledFooterLink>
                                    </li>
                                    <li>
                                        <StyledFooterLink
                                            target="_blank"
                                            href="https://intercom.help/bladebinge/en/collections/3435135-welcome-to-bladebinge"
                                        >
                                            {t('common:navigation.welcome_center')}
                                        </StyledFooterLink>
                                    </li>
                                </StyledFooterLinkList>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    component="div"
                                    variant="h6"
                                    sx={{
                                        textDecoration: 'underline',
                                        mb: 0,
                                        p: 0,
                                        fontSize: '1.4em',
                                        textAlign: 'right'
                                    }}
                                >
                                    {t('common:navigation.general_info')}
                                </Typography>
                                <StyledFooterLinkList>
                                    <li>
                                        <StyledFooterLink href="/contact_us">
                                            {t('common:navigation.contact_us')}
                                        </StyledFooterLink>
                                    </li>
                                    <li>
                                        <StyledFooterLink href="/join_mailing_list">
                                            {t('common:navigation.mailing_list')}
                                        </StyledFooterLink>
                                    </li>
                                    <li>
                                        <StyledFooterLink href="/privacy_policy">
                                            {t('common:navigation.privacy_policy')}
                                        </StyledFooterLink>
                                    </li>
                                    {isSignedIn && (
                                        <li>
                                            <StyledFooterLink
                                                id="footer_referral_program_link"
                                                href="/referral_program"
                                            >
                                                {t('common:navigation.referral_program')}
                                            </StyledFooterLink>
                                        </li>
                                    )}
                                    <li>
                                        <StyledFooterLink href="/returns_and_cancellations">
                                            {t('common:navigation.returns_and_cancellations')}
                                        </StyledFooterLink>
                                    </li>
                                    <li>
                                        <StyledFooterLink href="/shipping_policy">
                                            {t('common:navigation.shipping_policy')}
                                        </StyledFooterLink>
                                    </li>
                                    <li>
                                        <StyledFooterLink href="/terms_of_service">
                                            {t('common:navigation.terms_of_service')}
                                        </StyledFooterLink>
                                    </li>
                                </StyledFooterLinkList>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item>
                        <StyledCopyrightWrap>
                            <div>
                                <NextImage
                                    alt={t('navigation.logo_alt')}
                                    src={getCdnUrl('/logos/bladebinge_logo_white_230w_80h.png')}
                                    width={72}
                                    height={25}
                                />
                            </div>
                            <Typography variant="caption">© {new Date().getFullYear()}</Typography>
                            <Typography variant="caption"> {t('common:footer.copyright_predicate')} </Typography>
                            {showBuildVersion && (
                                <div>
                                    <Typography
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center',
                                            fontStyle: 'italic',
                                            color: (theme) => theme.palette.getContrastText(theme.palette.common.black)
                                        }}
                                        variant="caption"
                                    >
                                        {process.env.ENV_BUILD_VERSION}
                                    </Typography>
                                </div>
                            )}
                            {referralCode && (
                                <div>
                                    <Typography
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center',
                                            fontStyle: 'italic',
                                            color: (theme) => theme.palette.getContrastText(theme.palette.common.black)
                                        }}
                                        variant="caption"
                                    >
                                        referred by ({referralCode})
                                    </Typography>
                                </div>
                            )}
                        </StyledCopyrightWrap>
                    </Grid>
                </Grid>
            </Grid>
        </StyledFooterWrap>
    );
};
